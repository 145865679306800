import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faEdit as falEdit, faQuoteLeft as falQuoteLeft, faQuoteRight as falQuoteRight} from '@fortawesome/pro-light-svg-icons';
import { faSearch as farSearch, faCircle as farCircle, faArrowUp as farArrowUp, faLongArrowRight as farLongArrowRight, faRepeatAlt as farRepeatAlt } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faChevronLeft, faChevronDown, faCircle as fasCircle, faSearch as fasSearch, faChevronUp as fasChevronUp, faLink as fasLink, faDownload as fasDownload, faEdit as fasEdit, faSpinner as fasSpinner, faEnvelope as fasEnvelope, faBriefcase as fasBriefcase, faFileDownload as faFileDownload, faBars as fasBars, faPhone as fasPhone } from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

// light
library.add(falEdit, falQuoteLeft, falQuoteRight);
// regular
library.add(farSearch, farCircle, farArrowUp, farLongArrowRight, farRepeatAlt);
// solid
library.add(faChevronRight, faChevronLeft, faChevronDown, fasCircle, fasSearch, fasChevronUp, fasLink, fasDownload, fasEdit, fasSpinner, fasBriefcase, fasEnvelope, faFileDownload, fasBars, fasPhone );
// brands
library.add(faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP, faGooglePlusG);

dom.i2svg();
dom.watch();
